import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

import ProjectsFeed from '@/components/Projects/Feed'

export default () => (
  <StaticQuery
    query={graphql`
      query SecretProjectsFeedQuery {
        allMarkdownRemark(
          filter: {
            frontmatter: {
              type: { eq: "project" }
              layout: { eq: "Project" }
              secret: { eq: true }
            }
          }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                id
                title
                video {
                  active
                  url
                }
                thumbnail {
                  author
                  image {
                    childImageSharp {
                      gatsbyImageData(
                        width: 800,
                        quality: 45
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: NONE
                      )
                    }
                  }
                }
              }
            }
          }
        }
        order: markdownRemark(
          frontmatter: { templateKey: { eq: "projectsOrder" } }
        ) {
          frontmatter {
            projects
          }
        }
      }
    `}
    render={(data, count) => <ProjectsFeed data={data} count={count} />}
  />
)
