import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'

import SEO from '@/helpers/SEOHelper.js'

import Layout from '@/components/Layout'
import SecretProjectsFeed from '@/components/Projects/SecretProjectsFeed'
import Image from '@/components/Image'

export const ProjectsPageTemplate = ({ hero }) => {
  return (
    <>
      <section className='hero hero--small hero--limited'>
        <div className='hero__wrapper'>
          <div className='container'>
            <div className='hero__inner'>
              <h2 className='title-6 text-accent' data-aos='fade-right'>
                {hero.subheading}
              </h2>
              <h1 className='hero__title' data-aos='fade-right'>
                {hero.heading}
              </h1>
              <div data-aos='fade-right'>
                <ReactMarkdown
                  children={hero.description}
                  className='hero__content'
                />
              </div>
            </div>
            <div className='hero__images hero__images--projects'>
              <Image
                className='hero-image op-30 hero-image--1'
                aos='fade-left'
                author={hero.image1.author}
                image={hero.image1.image}
              />

              <Image
                className='hero-image op-30 hero-image--wide'
                aos='fade-left'
                author={hero.image2.author}
                image={hero.image2.image}
              />
            </div>
          </div>
        </div>
      </section>
      <section className='projects'>
        <div className='container'>
          <SecretProjectsFeed />
        </div>
      </section>
    </>
  )
}

const ProjectsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const seo = frontmatter.seo

  return (
    <Layout>
      <Helmet>
        <meta name='description' content={SEO.description(seo.description)} />
        <meta property='og:title' content={SEO.title(seo.title)} />
        <meta
          property='og:description'
          content={SEO.description(seo.description)}
        />
        <meta property='og:image' content={SEO.image(seo?.image?.publicURL)} />
        <title>{SEO.title(seo.title)}</title>
        <meta NAME='robots' CONTENT='noindex,nofollow' />
      </Helmet>
      <ProjectsPageTemplate hero={frontmatter.hero} />
    </Layout>
  )
}

ProjectsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default ProjectsPage

export const pageQuery = graphql`
  query SecretProjectsPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "secret-projects" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(
              width: 2000
              quality: 45
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        hero {
          subheading
          heading
          description
          image1 {
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 385
                  quality: 45
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            author
          }
          image2 {
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 790
                  quality: 45
                  formats: [AUTO, WEBP, AVIF]
                  transformOptions: { grayscale: true }
                )
              }
            }
            author
          }
        }
        seo {
          title
          description
          image {
            publicURL
          }
        }
      }
    }
  }
`
